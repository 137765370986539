import React, { useEffect } from 'react';
import { navigate, PageProps } from 'gatsby';

import { Translate } from 'src/components/Translate';
import { LoadingThrobber } from 'src/components/atom/icons';

const Callback: React.FC<PageProps> = ({ location }) => {
  const { search } = location;
  const i18n = {
    loading: 'pages.callback.loading',
  };

  useEffect(() => {
    if (!search) {
      navigate('/my-account/');
    }
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <LoadingThrobber width={40} height={40} />
      <p className="text_3">
        <Translate resourceKey={i18n.loading} />
      </p>
    </div>
  );
};

export default Callback;
